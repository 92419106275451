import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Default from "./layouts/Default";
import Home from "./screens/Home";

class App extends Component {
  render() {
    return (
        <Router>
          <Switch>
            <Default>
              <Route exact path="/" component={Home}></Route>
            </Default>
          </Switch>
        </Router>
    );
  }
}

export default App;

