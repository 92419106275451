import React, { Component } from 'react';
import './styles.css';

class Footer extends Component {
    render() {
        return (
            <div id="footer">
                Footer
            </div>
        );
    }
}

export default Footer;