import React, { Component } from 'react';
import './styles.css';
import {Container, Row, Col, Navbar, Nav} from 'react-bootstrap';


class Header extends Component {
    render() {
        return (
            <div id="header">
                        <Nav
                            fill
                            activeKey="/home"
                            onSelect={selectedKey => alert(`selected ${selectedKey}`)}
                        >
                            <Container>
                                <Row>
                                    <Col className="text-left">
                                        <Nav.Item>
                                            <Nav.Link href="/home">Services</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-1">Work</Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col className="text-center">
                                        <Nav.Item>
                                            <Navbar.Brand href="/">
                                                Sigglo
                                            </Navbar.Brand>
                                        </Nav.Item>
                                    </Col>
                                    <Col className="text-right">
                                        <Nav.Item>
                                            <Nav.Link href="/home">About</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-1">Contact</Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                </Row>

                            </Container>

                        </Nav>
            </div>
        );
    }
}

export default Header;