import React, { Component } from 'react';
import './styles.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import chart from './../../assets/images/chart.svg';
import PrimaryButton from "../../components/PrimaryButton";

class Home extends Component {
    render() {
        return (
            <Container fluid id="home-page">
                <Row className="align-items-center">
                    <Col className="align-middle">
                        <h1>Web Solutions for Online Success</h1>
                        <h2>The goal is not to build a website.<br />
                            The goal is to build your business.</h2>
                        <PrimaryButton>Get a quote</PrimaryButton>
                    </Col>
                    <Col>
                        <img className="img-fluid" src={chart} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Home;